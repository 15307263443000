.contacts {
	display: grid;
	grid-template-columns: 2fr minmax(440px, 1fr);
	grid-gap: 15px;
	padding-top: 70px;
	padding-bottom: 150px;

	@media (max-width: 1199px) {
		padding-top: 50px;
		padding-bottom: 80px;
	}
	@media (max-width: 980px) {
		grid-template-columns: 2fr 1fr;
	}
	@media (max-width: 768px) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media (max-width: 680px) {
		grid-template-columns: 100%;
	}
	&__wrapper {
		overflow-x: hidden;
	}
	h1 {
		grid-column: 1/-1;
		order: -2;
	}
	&__map {
		width: 100%;

		@media (max-width: 680px) {
			width: calc(100% + 30px);
			margin: 0 -15px;
		}
		> div {
			width: 100%;
			height: 100%;
			max-width: 915px;;
			min-height: 565px;
			border: none;

			@media (max-width: 680px) {
				min-height: 350px;
			}
		}
		.ymaps-2-1-79-hint {
			box-shadow: none;
			&__text {
				background: none;
			}
		}
		.map-img {
			width: 250px;
			padding: 0px;
			font-size: 0;
			line-height: 0;
			border-radius: 15px;
			border: none;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.5);
			box-sizing: border-box;

			img {
				border-radius: 15px;
			}
		}
	}
	&__block {
		position: relative;
		padding: 60px 20px 70px 60px;
		background-color: #fff;

		@media (max-width: 980px) {
			padding: 40px;
		}
		@media (max-width: 680px) {
			order: -1;
		}
		@media (max-width: 480px) {
			padding: 30px 20px;
		}
		@media (max-width: 400px) {
			padding: 30px 15px;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 70vw;
			height: 100%;
			background-color: #fff;
		}
		* {
			position: relative;
			z-index: 1;
		}
		p {
			padding-top: 0;
			padding-bottom: 0;
		}
		.tel,
		.mail,
		.adr,
		.time {
			position: relative;
			display: block;
			margin-bottom: 20px;
			padding-left: 43px;
			font-size: 18px;
		}
		.tel {
			margin-bottom: 30px;
			font-size: 24px;
			font-weight: 500;
			line-height: 1.1;

			&::before {
				top: 2px;
				width: 24px;
  				height: 24px;
  				margin: 0;
				border-width: 2px;
				border-color: rgb(230, 228, 228);
				border-style: solid;
				border-radius: 50%;
				background-image: url(/assets/images/contacts_icon_tel.svg);
				background-size: 14px;
			}

			a {
				display: block;
				margin-bottom: 2px;
			}
			
		}
		@media (max-width: 480px) {
			.tel,
			.mail,
			.adr,
			.time {
				padding-left: 38px;
			}
			.tel {
				font-size: 22px;
			}
		}
		@media (max-width: 400px) {
			.tel {
				font-size: 20px;
			}
		}
		.mail {
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 30px;
  				height: 30px;
  				margin-top: -15px;
				background-image: url(/assets/images/contacts_icon_mail.png);
				background-position: center;
				background-repeat: no-repeat;
			}

			a:hover {
				text-decoration: underline;
			}
		}
		.adr {
			
			&::before {
				top: 0;
				width: 30px;
  				height: 30px;
  				margin-top: 0;
				background-image: url(/assets/images/contacts_icon_adr.png);
			}
		}
		.time {
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 30px;
  				height: 30px;
  				margin-top: 0;
				background-image: url(/assets/images/contacts_icon_time.png);
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
	&__block_btns {
		display: flex;
		column-gap: 12px;

		.contacts__block_btn {
			background-color: #691b1b;
			color: #fff;
			font-size: 14px;
			font-weight: 600;
			width: 100%;
			max-width: 160px;
			padding: 5px 10px;
			border-radius: 5px;
			border: none;
		}

		@media (min-width: 481px) {
			margin-top: 40px;
		}

		.vk {
			display: block;
			padding-left: 43px;
			line-height: 0;	
		}
	}
	&__slider {
		position: relative;
		grid-column: 1/-1;
		margin: 105px 0 0;

		@media screen and (max-width: 768px) {
			margin-top: 50px;
		}
		@media screen and (max-width: 480px) {
			margin-top: 30px;
		}
		.swiper-button-prev {
			left: -75px;

			@media screen and (max-width: 1560px) {
				left: -40px;
			}
			@media screen and (max-width: 1480px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -75px;

			@media screen and (max-width: 1560px) {
				right: -40px;
			}
			@media screen and (max-width: 1480px) {
				right: -15px;
			}
		}
	}
}

