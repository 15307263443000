.error {
    padding: 115px 0 145px;
    text-align: center;
    background-image: url(/assets/images/error_bg.jpg);

    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }

    &__img {
        position: relative;
        display: inline-block;
        margin-bottom: 100px;

        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }

        &::before {
            content: '4';
            position: absolute;
            top: 50%;
            left: -200px;
            transform: translateY(-55%);
            color: #111111;
            font-size: 450px;
            line-height: 1;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                left: -35%;
                font-size: 35vw;
            }
        }
        &::after {
            content: '4';
            position: absolute;
            top: 50%;
            right: -180px;
            transform: translateY(-55%);
            color: #111111;
            font-size: 450px;
            line-height: 1;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                right: -35%;
                font-size: 35vw;
            }
        }

        img {
            position: relative;
            z-index: 1;
            max-width: 347px;

            @media screen and (max-width: 768px) {
                width: 40vw;
            }
        }
    }
    &__title {
        padding: 0 0 15px;
        font-size: 34px;
        font-weight: 400;
        color: $red;

        @media screen and (max-width: 480px) {
            font-size: 25px;
        }
    }
    p {
        max-width: 630px;
        margin: 0 auto;
        text-align: center;

        a {
            color: $red;
        }
    }
    &__links {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 15px;
        margin: 35px 0 0;

        @media screen and (max-width: 480px) {
            margin-top: 20px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 60px;
            padding: 10px 35px;
            line-height: 1.2;
            font-size: 16px;
            color: $red;
            font-weight: 600;
            text-align: center;
            border-radius: 5px;
            background-color: rgb(224, 219, 217);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            transition: background-color 0.2s;

            &:hover {
                background-color: #c6bbb8;
            }
        }
    }
}