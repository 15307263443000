.slick-prev, .slick-next,
.swiper-button-next, .swiper-button-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 42px;
    height: 42px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    border-radius: 5px;
    background: $red;
	transition: 0.2s;
	z-index: 2;
	opacity: 1;

	&::before {
		content: ''; 
	    position: absolute; /* Абсолютное позиционирование */
	    left: 50%; 
	    top: 50%; /* Положение треугольника */
	    transform: translate(0, -50%);
		margin-left: -3px;
	    border: 8px solid transparent; 
	    border-left: 10px solid #fff;
	}
	&:after {
		display: none;
	}
	&:hover,
	&:focus {
		opacity: 1;
		background: rgb(74, 20, 20);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
}
.swiper-button-prev {
	&::before {
		margin-left: -10px;
		border-left: none;
	    border-right: 10px solid #fff;
	}
}
.main-top {
	position: relative;
	padding-top: 180px;
	padding-bottom: 150px;
	color: #fff;
	background-image: url(/assets/images/main_top.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media screen and (max-width: 991px) {
		padding-top: 10vw;
		padding-bottom: 9vw;
	}
	@media screen and (max-width: 480px) {
		padding-top: 50px;
		padding-bottom: 30px;
		background-position: 30%;
	}
	@media screen and (max-width: 420px) {
		background-size: 180%;
	}
	@media screen and (max-width: 360px) {
		background-position: 40%;
		background-size: cover;
	}

	p {
		max-width: 50%;
		padding: 13px 0;
		font-size: 32px;
		font-weight: 500;

		@media screen and (max-width: 1280px) {
			font-size: 2vw;
		}
		@media screen and (max-width: 480px) {
			max-width: 75%;
			padding: 5px 0;
			font-size: 14px;
		}
	}
	.main-top__link {
		cursor: pointer;
	}

	.main-top__title {
		position: relative;
		margin-bottom: 15px;
		padding: 0 0 70px;
		text-transform: uppercase;
		font-size: 60px;
		font-weight: 700;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 134px;
  			height: 4px;
			background-color: rgb(255, 255, 255);
		}
		@media screen and (max-width: 1280px) {
			font-size: 4vw;
		}
		@media screen and (max-width: 991px) {
			padding-bottom: 5vw;
		}
		@media screen and (max-width: 480px) {
			margin-bottom: 5px;
			padding-bottom: 20px;
			font-size: 20px;

			&::after {
				width: 71px;
	  			height: 1px;
			}
		}
	}
}
.main-catalog {
	padding-top: 65px;
	padding-bottom: 90px;

	@media screen and (max-width: 1199px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 768px) {
		padding-top: 50px;
		padding-bottom: 40px;
	}
	&__list {
		display: grid;
		grid-template-columns: 1.73fr 1.38fr 1fr;
		grid-gap: 30px;
		margin: 0;
		padding: 20px 0 35px;

		@media screen and (max-width: 900px) {
			grid-gap: 15px;
		}
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 480px) {
			grid-template-columns: 1.2fr 1fr;
			grid-gap: 20px 15px;
			margin: 0 -15px;
		}
		li {
			display: flex;
			flex-direction: column;
			background-size: cover;

			&:nth-child(1) {
				background-image: url(/assets/images/main_catalog_1.jpg);

				@media screen and (max-width: 480px) {
					grid-column: 1/-1;
				}
			}
			&:nth-child(2) {
				grid-row: 1/3;
				grid-column: 2/3;
				background-image: url(/assets/images/main_catalog_2.jpg);
				
				@media screen and (max-width: 768px) {
					grid-row: 2/4;
					grid-column: 2/3;
				}
				@media screen and (max-width: 480px) {
					grid-row: 3/5;
					grid-column: 2/3;
				}
			}
			&:nth-child(3) {
				background-image: url(/assets/images/main_catalog_3.jpg);
				
				@media screen and (max-width: 768px) {
					grid-row: 2/3;
					grid-column: 1/2;
				}
				@media screen and (max-width: 480px) {
					grid-row: auto;
				}
			}
			&:nth-child(4) {
				background-image: url(/assets/images/main_catalog_4.jpg);

				@media screen and (max-width: 480px) {
					grid-row: 2/3;
					grid-column: 1/-1;
				}
			}
			&:nth-child(5) {
				background-image: url(/assets/images/main_catalog_5.jpg);
			}
		}
		.main-catalog__item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			flex-grow: 1;
			font-size: 24px;
			color: #fff;
			font-weight: 500;
			line-height: 1.2;
			transition: 0.2s;

			@media screen and (max-width: 1024px) {
				font-size: 20px;
			}
			@media screen and (max-width: 768px) {
				font-size: 18px;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
	  			height: 180px;
	  			max-height: 100%;
				background-image: linear-gradient(to top, rgb(0,0,0) 0%, rgba(0,0,0,0) 100%);
  				opacity: 0.7;
			}
			&:hover {
				box-shadow: 0px 0px 8.3px 1.7px rgba(0, 0, 0, 0.29);
			}
			img {
				opacity: 0;
			}
			&-name {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1;
				padding: 20px 25px;

				@media screen and (max-width: 768px) {
					padding: 12px 10px;
				}
			}
		}
	}
}
.main-steps {
	padding-top: 50px;
	padding-bottom: 115px;
	background-image: url(/assets/images/main_steps.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media screen and (max-width: 768px) {
	    padding-top: 20px;
	    padding-bottom: 60px;
	}

	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 100px;
		max-width: 1160px;
		margin: 20px auto 0;
		padding: 0;
		counter-reset: myCounter;

		@media screen and (max-width: 1199px) {
		    grid-row-gap: 50px;
		}
		@media screen and (max-width: 900px) {
		    grid-column-gap: 30px;
		}
		@media screen and (max-width: 620px) {
		    grid-template-columns: 1fr;
		    grid-row-gap: 35px;
		}
		li {
			position: relative;
			display: flex;
			align-items: center;
			min-height: 105px;
			padding-left: 135px;
			padding-top: 20px;
			font-size: 20px;
			line-height: 1.3;
			list-style: none;

			@media screen and (max-width: 900px) {
			    align-items: flex-start;
			    padding-top: 30px;
			}
			@media screen and (max-width: 900px) {
			    font-size: 18px;
			}
			@media screen and (max-width: 480px) {
				align-items: center;
				min-height: 75px;
			    padding-left: 90px;
			    padding-top: 0;
			    font-size: 14px;
			}

			&::before {
			    position: absolute;
			    top: 0;
			    left: 0;
			    counter-increment: myCounter;
			    content: counter(myCounter);
			    font-size: 64px;
			    color: #d29349;
			    line-height: 1;
			    font-weight: 800;
			    opacity: 0.3;

			    @media screen and (max-width: 480px) {
				    display: none;
				}
			}
			&::after {
				content: '';
			    position: absolute;
			    z-index: 2;
			    top: 20px;
			    left: 20px;
			    width: 91px;
			    height: 87px;
			    background-position: center;
			    background-repeat: no-repeat;

			    @media screen and (max-width: 480px) {
					top: 0;
					left: 0;
					width: 71px;
					height: 75px;
				}
			}
			&:nth-child(1)::after {
				background-image: url(/assets/images/svg/tel.svg);
				background-size: auto 87px;

				@media screen and (max-width: 480px) {
					background-size: contain;
				}
			}
			&:nth-child(2)::after {
				background-image: url(/assets/images/svg/dver.svg);
				background-size: auto 85px;

				@media screen and (max-width: 480px) {
					background-size: auto 73px;
				}
			}
			&:nth-child(3)::after {
				top: 30px;
				background-image: url(/assets/images/svg/document.svg);
				background-size: 83px;

				@media screen and (max-width: 480px) {
					top: 0;
					background-size: 85px;
					background-position: -7px;
				}
			}
			&:nth-child(4)::after {
				top: 30px;
				background-image: url(/assets/images/svg/dostavka.svg);
				background-size: 91px;

				@media screen and (max-width: 480px) {
					top: 0;
					background-size: contain;
				}
			}

			p {
				padding: 0;
			}
			span {
				display: block;
				margin-top: 5px;
			}
			a {
				cursor: pointer;
			}
			a:hover {
				color: #982020;
			}
		}
	}
}
.main-services {
	padding-top: 70px;
	padding-bottom: 70px;

	@media screen and (max-width: 1199px) {
	    padding-top: 50px;
		padding-bottom: 50px;
	}
	@media screen and (max-width: 768px) {
		padding-top: 30px;
		padding-bottom: 20px;
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 65px 30px;
		margin: 0;
		padding: 20px 0 0;
		list-style: none;
		font-size: 15px;

		@media screen and (max-width: 1170px) {
			grid-gap: 40px 30px;
		}
		@media screen and (max-width: 768px) {
			grid-gap: 40px 20px;
			grid-template-columns: repeat(2, 1fr);
			padding: 0;
		}
		@media screen and (max-width: 520px) {
			grid-template-columns: 1fr;
		}
		li:nth-child(4) {
			@media screen and (min-width: 769px) {
				grid-column: 1/-1;
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-gap: 65px 30px;

				@media screen and (max-width: 1170px) {
					grid-gap: 40px 30px;
				}
				img {
					margin-bottom: 0;
				}
			}
		}
		img {
			margin-bottom: 15px;
			line-height: 0;
			border-radius: 14px;
		}
		p {
			padding: 13px 0;

			@media screen and (max-width: 520px) {
				padding: 9px 0;
			}
		}
		strong {
			display: block;
			margin: 0 0 5px;
			font-size: 24px;
			list-style: 1.1;
			font-weight: 400;

			@media screen and (max-width: 520px) {
				font-size: 22px;
			}
		}
	}
}

.main-partners {
	padding-bottom: 150px;

	@media screen and (max-width: 1199px) {
		padding-bottom: 100px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 70px;
	}

	&-list {
		position: relative;
		margin: 35px -15px 0;

		@media screen and (max-width: 1480px) {
			margin: 35px 0 0;
		}
		@media screen and (max-width: 768px) {
			margin-top: 15px;
		}

		.slick-slide {
			padding: 0 15px; 
		}
		@media screen and (max-width: 768px) {
			.slick-slide {padding: 0 10px;}
		}
		.swiper-button-prev {
			left: -75px;

			@media screen and (max-width: 1560px) {
				left: -40px;
			}
			@media screen and (max-width: 1480px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -75px;

			@media screen and (max-width: 1560px) {
				right: -40px;
			}
			@media screen and (max-width: 1480px) {
				right: -15px;
			}
		}
		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;

			@media screen and (max-width: 768px) {
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			}
		}
	}
}