.part-types {
  &_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;

    @media (max-width: 992px) {
      column-gap: 20px;
    }
  }
  &_img {
    flex-shrink: 0;
    padding-top: 5px;
    @media (max-width: 768px) {
      width: 100%;
      & img {
        margin: 0 auto;
      }
    }
  }
  &_text {
    flex: 1;
    & p {
      padding: 0;
      margin: 0 0 20px;

      @media (max-width: 992px) {
        margin-bottom: 15px;
      }
      @media (max-width: 768px) {
        flex: initial;
        width: 100%;
      }
    }
  }
}

.number-list {
  list-style: none;
  counter-reset: numbers;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  padding: 0;
  margin: 0 0 20px;

  & li {
    position: relative;
    counter-increment: numbers;
    padding-left: 52px;
    min-height: 45px;
  }
  & li::before {
    content: counter(numbers);
    position: absolute;
    left: 0;
    top: 0;
    line-height: 0.8em;
    font-size: 56px;
    font-weight: 800;
    color: #e1ceb7;
  }
  @media (max-width: 1280px) {    
    justify-content: flex-start;
  }
  @media (max-width: 992px) {
    & li {
      padding-left: 40px;
      line-height: 1.2em;
      min-height: 40px;
    }
    & li::before {
      font-size: 48px;
    }
  }
  @media (max-width: 480px) {
    & li {
      padding-left: 36px;
      min-height: 34px;
    }
    & li::before {
      font-size: 42px;
    }
  }
}
@media (max-width: 1280px) {
  .kompozit-text .number-list {
    column-gap: initial;
  }
  .kompozit-text .number-list li {
    width: 31.5%;
  }
}
@media (max-width: 992px) {
  .kompozit-text .number-list li {
    width: 48.5%;
  }
}
@media (max-width: 480px) {
  .kompozit-text .number-list li {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .kompozit-text .number-list li br {
    display: none;
  }
}

.brand-preffs {
  padding-top: 35px;
  padding-bottom: 20px;
  margin-bottom: 60px;

  &--kapelli {
    background: url('/assets/images/kapelli_bg.png') right center / cover no-repeat, #e4e4e4;
  }
  @media (min-width: 1921px) {
    &--kapelli {
      background-size: auto;
      background-position: center;
    }
  }
  &_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;

    @media (max-width: 992px) {
      column-gap: 20px;
    }
  }
  &_img {
    flex-shrink: 0;
    padding-top: 5px;
    & img {
      display: block;
    }
    @media (max-width: 768px) {
      width: 100%;
      & img {
        margin: 0 auto;
      }
    }
  }
  &_text {
    flex: 1;
    & p {
      padding: 0;
      margin: 0 0 15px;

      @media (max-width: 992px) {
        margin-bottom: 15px;
      }
      @media (max-width: 768px) {
        flex: initial;
        width: 100%;
      }
    }
  }
}