.popup {
	padding: 85px 40px 30px;
	text-align: left;

	@media screen and (max-width: 680px) {
		padding: 30px;
	}

	.popup__title {
		padding: 0 0 35px;
		font-size: 24px;
		line-height: 1.2;

		@media screen and (max-width: 480px) {
			padding-bottom: 15px;
		}
	}
	.popup__text {
		max-width: 335px;
		margin: 0 auto;
		padding: 0 0 35px;
		font-size: 16px;
		text-align: center;

		@media screen and (max-width: 480px) {
			padding-bottom: 15px;
		}
	}
}
.form {
	width: 350px;
	max-width: 100%;

	&__entry {
		width: 100%;
		margin-bottom: 28px;
		padding: 15px 20px;
		font-size: 14px;
		border-width: 1px;
		border-color: rgb(222, 221, 221);
		border-style: solid;
		background-color: rgb(255, 255, 255);
		transition: 0.2s;

		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}

		&:focus {
		outline: none;
		border-bottom-color: $gray;
		}
	}
	textarea {
		height: 100px;
	}
	::-webkit-input-placeholder {color:#a6a6a6; font-size: 14px; }
	::-moz-placeholder          {color:#a6a6a6; font-size: 14px; }/* Firefox 19+ */
	:-moz-placeholder           {color:#a6a6a6; font-size: 14px; }/* Firefox 18- */
	:-ms-input-placeholder      {color:#a6a6a6; font-size: 14px; }

  	&__check {
  		margin-bottom: 28px;

  		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}

	    label {
	      position: relative;
	      display: block;
	      padding-left: 35px;
	      font-size: 12px;
	      line-height: 1.2;

	      &::before {
	        content: '';
	        position: absolute;
	        left: 0;
	        top: 0;
	        width: 22px;
	        height: 22px;
	        border: 2px solid #3c3c3b;;
	        background-color: #3c3c3b;
	        background-repeat: no-repeat;
	        background-position: center;
	        background-size: 18px;
	        cursor: pointer;
	        transition: 0.2s;
	      }
	      a {
	        text-decoration: underline;

	        &:hover {
	          text-decoration: none;
	        }
	      }
	    }
	    input:checked + label::before {
	    	background-color: #fff;
	      	background-image: url(/assets/images/svg/checked.svg);
	    }
  	}
  	&__captcha {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 13px;
		margin-bottom: 35px;

		@media screen and (max-width: 480px) {
			margin-bottom: 20px;
		}

		.form__entry {
			margin-bottom: 0;
			text-align: center;
		}
		.form__error {
			bottom: auto;
			top: 100%;
		}
	}
	&__group {
		position: relative;
	}
	&__error {
		position: absolute;
		bottom: 10px;
		left: 0;
		font-size: 11px;
		color: red;

		@media screen and (max-width: 480px) {
			bottom: 5px;
		}
	}
	.form__btn {
		width: 100%;
		padding: 13px;
		text-align: center;
		font-size: 14px;
		color: #fff;
		transition: 0.2s;
		border: none;
		background-color: #3c3c3b;
		cursor: pointer;

		&:hover {
			color: #000;
			background-color: #e0dbd9;
		}
	}
}
.modal {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	outline: none;
	position: fixed;
	-webkit-tap-highlight-color: transparent;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background: rgba(136, 136, 136, 0.4);
	transition-timing-function: cubic-bezier(.22,.61,.36,1);
	text-align: right;
	overflow: initial;
	z-index: 99992;

	&__inner {
		position: relative;
		width: 100%;
		max-width: 550px;
		height: 100vh;
		padding-top: 95px;
		overflow: auto;
		background-color: #fff;

		@media screen and (max-width: 520px) {
			padding-top: 0;
		}
	}
	&__close {
		width: 100%;
		height: 70px;
		padding: 0 40px;
		background: transparent;
		border: 0;
		border-bottom: 2px solid #f1f1f1;
		z-index: 401;
		cursor: pointer;

		@media screen and (max-width: 520px) {
			padding: 0 15px;
		}

	  	svg {
			display: block;
			overflow: visible;
			position: relative;
			width: 35px;
			height: 35px;
			transform: rotate(45deg);
			opacity: .2;

			@media screen and (max-width: 520px) {
				margin-left: auto;
			}
		}
		&:hover svg {
		  opacity: 1;
		}
	}
}


