header {
  position: relative;
  background-color: $red;

  // ny-decor
  // &::before {
  //   content: "";
  //   position: absolute;
  //   height: 98px;
  //   width: 100%;
  //   left: 0;
  //   top: 100%;
  //   background: url(/assets/images/ny_decor.png) top center / auto repeat-x;
  //   z-index: 2;
  //   @media (max-width: 1360px) {
  //     background-size: 100%;
  //   }
  //   @media (max-width: 992px) {
  //     background-size: 150%;
  //   }
  // }
  // end ny-decor

  .header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto auto auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
    color: #fff;

    @media (min-width: 992px) {
      .header__btn:not(.header__btn--search) {
        margin-left: 10px;
      }
    }
    @media (max-width: 1200px) {
      grid-column-gap: 20px;
    }
    @media (max-width: 1080px) {
      grid-column-gap: 15px;
    }

    @media (max-width: 840px) and (min-width: 601px) {
      grid-template-columns: 1fr auto auto auto;
      grid-row-gap: 10px;
      padding-top: 10px;
      padding-bottom: 15px;

      .header__btn {
        grid-row: 1/2;
        grid-column: 3/5;

        &--search {
          grid-row: 2/3;
          grid-column: 3/4;
          margin-left: auto;
          margin-right: -40px;
        }
      }
      .toggle-wrapper {
        margin-left: auto;
      }
    }
    
    @media (max-width: 600px) {
      grid-template-columns: 1fr auto auto auto;
      grid-row-gap: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      .header__btn,
      .header__adr {
        display: none;
      }
      .header__btn--search {
        display: block;
      }      
      .header__phone {
        padding-left: 0;

        a[href^="tel:"] {
          position: relative;
          padding-left: 25px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 17px;
            height: 23px;
            margin-top: -11.5px;
            background-image: url(/assets/images/adr_white.png);
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        &::before,
        & > a:nth-child(1) {
          display: none;
        }
      }
    }
    @media (max-width: 520px) {
      .header__phone {
        font-size: 12px;
      }
    }
    @media (max-width: 480px) {
      grid-column-gap: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 420px) {
      .toggle-wrapper,
      .header__btn--search {
        width: 35px;
        height: 35px;
      }
    }
    @media (max-width: 400px) {
      .header__phone {
        font-size: 11px;

        a[href^="tel:"] {
          padding-left: 0;
        }
        a::before {
          display: none;
        }
        .phones-row {
          column-gap: 2px;
        }
        .messenger {
          width: 15px;
        }
      }
    }    
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    .header {
      padding-top: 10px;
      padding-bottom: 12px;

      @media (min-width: 981px) {
        grid-column-gap: 40px;
      }
      @media (max-width: 840px) and (min-width: 601px) {
        grid-template-columns: 1fr auto auto auto auto;
        grid-column-gap: 10px;
        padding-top: 10px;
        padding-bottom: 15px;

        .header__logo {
          grid-row: 1/2;
        }
        .header__phone,
        .header__adr {
          padding-left: 50px;
          font-size: 13px;
        }
        .header__adr {
          padding-left: 0;

          &::before {
            display: none;
          }
        }
        .header__btn--search {
          grid-row: auto;
          grid-column: auto;
          margin: 0;
        }
      }
    }
    .toggle-wrapper {
      display: flex;
    }
    .header__btn {
      display: none;

      &--search {
        display: block;
      }
    }
    nav {
      @media (min-width: 841px) {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      &:not(.opened) {
        display: none;
      }
    }
    .menu {
      font-weight: 400;
      > li {
        > a {
          @media screen and (min-width: 841px) {
            border-bottom: 3px solid #fff;

            &:hover,
            &.active {
              border-color: $red;
            }
          }
        }
      }
    }
  }
}

// ny-logo
// .header__logo {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     width: 280px;
//     height: 50px;
//     background: url(/assets/images/ny_slogan.png) center center / 100% auto no-repeat;
//     left: 85%;
//     top: -15px;
//     @media (max-width: 1380px) {
//       width: 240px;
//       height: 40px;
//       left: 65%;
//     }
//     @media (max-width: 1280px) {
//       background-image: url(/assets/images/ny_img.png);
//       width: 150px;
//       height: 52px;
//     }
//     @media (max-width: 1140px) {
//       width: 124px;
//       height: 44px;
//     }
//     @media (max-width: 992px) {
//       display: none;
//     }
//   }
// }
// end ny-logo

.header__phone {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;

  a {
    display: block;
  }
}
.header__adr {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
}
.header__btn {
  padding: 15px 30px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  transition: 0.15s;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 15px;
  }
  @media (max-width: 840px) {
    padding: 10px;
  }
  &:hover {
    background-color: #c6bbb8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  &--search {
    width: 45px;
    height: 45px;
    padding: 15px;
    background-image: url(/assets/images/search.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
  }
}
nav {
  background-color: #e0dbd9;

  @media screen and (max-width: 840px) {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: transparent;
    transform: translateX(-110%);
    transition: transform 0.4s, -webkit-transform 0.4s;

    &.opened {
      transform: translateX(0);
    }
  }
  @media screen and (max-width: 480px) {
    overflow: visible;
  }
}
.menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 15px;
  font-weight: 600;
  list-style: none;

  @media screen and (max-width: 980px) {
    padding: 0 5px;
  }
  @media screen and (max-width: 840px) {
    display: block;
    width: 85%;
    max-width: 360px;
    min-height: 100%;
    margin: 0;
    padding: 25px 0 25px 35px;
    background-color: $gray;
    box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: initial;
    overflow: auto;
  }

  > li {
    padding: 15px 0;
    text-align: center;

    @media screen and (max-width: 840px) {
      display: flex;
      padding: 0;
      border-bottom: 2px solid #ececec;
    }
    &:hover {
      .menu__arrow::before {
        filter: invert(20%) sepia(51%) saturate(3295%) hue-rotate(344deg)
          brightness(84%) contrast(93%);
      }
    }

    > a {
      display: block;
      padding: 3px 0;

      &:hover,
      &.active {
        color: $red;
      }

      @media screen and (min-width: 841px) {
        border-bottom: 3px solid transparent;

        &:hover,
        &.active {
          border-color: $red;
        }
      }
      @media screen and (max-width: 980px) {
        font-size: 14px;
      }
      @media screen and (max-width: 840px) {
        flex-grow: 1;
        padding: 20px 0;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
      }
    }
    .menu__arrow {
      display: none;

      @media screen and (max-width: 840px) {
        position: relative;
        display: block;
        width: 45px;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%) rotate(-90deg);
          width: 10px;
          height: 6px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(/assets/images/svg/arrow_down2.svg);
          background-size: 10px;
        }
      }
    }
    @media screen and (min-width: 840px) {
      &:hover {
        .menu__lvl2 {
          display: grid;
        }
      }
    }
  }
  .menu__lvl2 {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 20;
    display: none;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-column-gap: 40px;
    grid-row-gap: 15px;
    align-items: flex-start;
    width: 580px;
    max-width: 100%;
    margin: 0;
    padding: 35px 40px;
    list-style: none;
    background-color: #eaeaea;
    box-shadow: 0px 0px 14px 0px rgba(63, 63, 63, 0.4);

    @media screen and (max-width: 840px) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      display: grid;
      grid-row-gap: 0;
      width: 100vw;
      width: 100lvw;
      max-width: 100vw;
      min-height: 100%;
      padding: 32px 10px;
      background: $gray;
      color: inherit;
      transform: translateX(-110%);
      transition: transform 0.4s;
      z-index: 10;

      &.active {
        visibility: visible;
        transform: translateX(0);
      }
    }
    &:not(.menu__lvl2--col3) {
      @media screen and (min-width: 841px) {
        > li:nth-child(9) {
          grid-column: 1/2;
          grid-row: 4/5;
        }
        > li:nth-child(10) {
          grid-column: 1/2;
          grid-row: 5/6;
        }
        > li:nth-child(11) {
          grid-column: 1/2;
          grid-row: 6/7;
        }
      }
      @media screen and (max-width: 841px) {
        > li:nth-child(9) {
          margin-top: 10px;
          border-top: 2px solid #ececec;
        }
      }
    }

    &--col3 {
      @media screen and (min-width: 841px) {
        width: 840px;
        grid-template-columns: 0.35fr 1fr;
        padding-bottom: 60px;

        > li:nth-child(2) {
          @media screen and (min-width: 841px) {
            grid-column: 2/3;
            grid-row: 1/3;
          }

          .menu__lvl3 {
            grid-template-columns: 1fr auto;
            grid-column-gap: 30px;
          }
        }
        > li:nth-child(4) {
          grid-row: span 5;
        }
      }
      @media screen and (max-width: 840px) {
        > li:nth-child(1),
        > li:nth-child(2),
        > li:nth-child(3) {
          order: -2;
          grid-column: auto;
          padding: 0;
          border-bottom: none;

          > a {
            font-size: 15px;
            font-weight: 400;
          }
        }
        > li:nth-child(4),
        > li:nth-child(5) {
          grid-column: 1/-1;
        }
        > li:nth-child(4) {
          order: -1;
          margin-top: 10px;
          border-top: 2px solid #ececec;
        }
        > li:nth-child(2) {
          order: -3;
          grid-column: 1/-1;
          margin-bottom: 10px;

          .menu__lvl3 {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
          }
        }
        .menu__lvl3 a {
          font-size: 13px;
        }
        .menu__lvl3 .menu__icon-link {
          margin-bottom: 0;
        }
      }
      .menu__lvl3 li:not(.menu__icon-link) {
        a {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-size: 14px;
          font-weight: 500;

          &::before {
            content: "";
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 37px;
            border-radius: 5px;
            background-color: rgb(255, 255, 255);

            @media screen and (max-width: 480px) {
              width: 45px;
              height: 28px;
            }
          }
          &:hover::before {
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
          }
          @media screen and (max-width: 480px) {
            font-size: 13px;
          }
        }
      }
    }
    @media screen and (min-width: 841px) {
      &--col1 {
        grid-template-columns: 1fr;
        width: 380px;
        left: 42%;
      }
    }
    a {
      display: block;
      max-width: 210px;
      line-height: 1.2;
      text-align: left;
      font-weight: 500;

      @media screen and (max-width: 840px) {
        max-width: 100%;
      }

      &:hover,
      &.active {
        color: #982020;
        text-decoration: underline;
      }
    }
    li {
      @media screen and (max-width: 840px) {
        grid-column: 1/-1;
        padding: 10px 0;
        border-bottom: 2px solid #ececec;

        a {
          font-weight: 600;
          font-size: 16px;
        }
      }
      &.menu__icon-link {
        @media screen and (max-width: 840px) {
          grid-column: auto;
          order: -1;
          margin-bottom: 15px;
          padding: 0;
          border-bottom: none;
        }
        a {
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-size: 14px;
          font-weight: 500;

          @media screen and (max-width: 480px) {
            font-size: 13px;
          }
        }
        &:hover {
          .menu__icon {
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
          }
        }
      }
      &.menu__back {
        grid-column: 1/-1;
        order: -5;
        margin-bottom: 20px;
        padding: 10px 0 10px 53px;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        background-image: url(/assets/images/svg/arrow_right.svg);
        background-position: 24px center;
        background-repeat: no-repeat;
        background-size: 22px;
        border-bottom: none;
        cursor: pointer;

        @media screen and (min-width: 841px) {
          display: none;
        }
      }
    }

    .menu__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 37px;
      border-radius: 5px;
      background-color: rgb(255, 255, 255);

      @media screen and (max-width: 480px) {
        width: 45px;
        height: 28px;
      }
      img {
        max-height: 100%;
      }
    }
  }
  .menu__lvl3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    align-items: flex-start;
    margin: 0;
    padding: 15px 0;
    list-style: none;

    ul {
      display: none;
    }
  }
}
.toggle-wrapper {
  display: none;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 840px) {
    display: flex;
  }
}
/* icon basic styles !!!YOU NEED THEM  */
.menu-icon {
  height: 20px;
  cursor: pointer;
  width: 28px;
  position: relative;
  overflow: hidden;
}
.menu-icon:before,
.menu-icon:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  content: "";
  display: block;
  width: 3px;
  height: 28px;
  border-radius: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background-color: $red;
  opacity: 0;
}
.menu-icon:before {
  position: absolute;
  top: 0;
  left: 13px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu-icon:after {
  position: absolute;
  top: 0;
  right: 13px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-icon .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  width: 100%;
  height: 3px;
  background-color: $red;
  border-radius: 0;
}
.menu-icon .bar-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-icon .bar-2 {
  position: absolute;
  top: 8px;
  left: 0;
}
.menu-icon .bar-3 {
  position: absolute;
  top: 17px;
  left: 0;
}
.menu-icon.opened .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.menu-icon.opened:before,
.menu-icon.opened:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
}

.menu-icon-3:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-icon-3:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu-icon-3:before,
.menu-icon-3:after {
  top: -2px;
}

.menu-icon-3.opened:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-icon-3.opened:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-icon-3.opened:before,
.menu-icon-3.opened:after {
  top: -4px;
}
.menu-icon-3.opened .bar {
  opacity: 0;
}
.menu-icon-3.opened .bar-1 {
  top: 10px;
}
.menu-icon-3.opened .bar-3 {
  top: 10px;
}
