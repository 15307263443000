.search__wrapper {
    padding: 40px 0 35px;
    background-color: rgb(234, 234, 234);

    @media (max-width: 991px) {
		padding: 20px 0;
	}
    @media (max-width: 768px) {
		padding: 15px 0;
	}
}
.search {
    display: flex;
    gap: 10px;

    input {
        flex-grow: 1;
        min-width: 0;
        padding: 10px 25px;
        font-size: 17px;
        border-radius: 5px;
        border: none;
        background-color: rgb(255, 255, 255);

        &::placeholder {
            color: #555555;
        }
        @media (min-width: 992px) {
            margin-right: 20px;
        }
        @media (max-width: 600px) {
            padding: 10px 15px;
            font-size: 15px;
        }
        @media (max-width: 380px) {
            padding: 10px;
        }
    }
    .search__btn {
        width: 46px;
        height: 45px;
        border: none;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        background-image: url(/assets/images/search.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 17px;
        flex-shrink: 0;
        cursor: pointer;

        &--close {
            background-color: $red;
            background-size: 18px;
            background-image: url(/assets/images/close.svg);
        }
        @media (max-width: 380px) {
            width: 35px;
            height: 35px;
		}
    }
}