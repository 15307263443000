@font-face {
	font-family: "GolosText";
	src: url("../fonts/Golos-Text_Regular.woff") format("woff"),
	url("../fonts/Golos-Text_Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "GolosText";
	src: url("../fonts/Golos-Text_Bold.woff") format("woff"),
	url("../fonts/Golos-Text_Bold.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: "GolosText";
	src: url("../fonts/Golos-Text_Black.woff") format("woff"),
	url("../fonts/Golos-Text_Black.woff2") format("woff2");
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}
@font-face {
	font-family: "GolosText";
	src: url("../fonts/Golos-Text_DemiBold.woff") format("woff"),
	url("../fonts/Golos-Text_DemiBold.woff2") format("woff2");
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: "GolosText";
	src: url("../fonts/Golos-Text_Medium.woff") format("woff"),
	url("../fonts/Golos-Text_Medium.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: "Terminator";
	src: url("../fonts/TerminatorCyr_Bold.woff") format("woff"),
	url("../fonts/TerminatorCyr_Bold.woff2") format("woff2");
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}