@import "normalize.scss";
@import "fonts.scss";
@import "var.scss";
@import "content.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container {
  max-width: 1390px;
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}
.tel,
.adr {
  position: relative;
  padding-left: 60px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 45px;
    height: 45px;
    margin-top: -22.5px;
    background-color: #fff;
    border-radius: 5px;
    background-image: url(/assets/images/tel.png);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.adr {
  &::before {
    background-image: url(/assets/images/adr.png);
  }
}
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
.no-scroll {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "GolosText", Georgia;
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
  background-color: $gray;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0.5em 0;
}

.uppercase {
  text-transform: uppercase;
}
.center {
  text-align: center;
}
a[href^="tel"] {
  cursor: auto;
  white-space: nowrap;
}
h1,
h2,
h3,
.h2 {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 35px 0;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 1199px) {
    padding: 35px 0;
    font-size: 36px;
  }
  @media screen and (max-width: 768px) {
    padding: 25px 0;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
}
.gray {
  background-color: $gray;
}
.swiper-pagination {
  .swiper-pagination-bullet {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 7px;
    padding: 0;
    border-radius: 50%;
    background-color: rgb(26, 26, 24);
    opacity: 0.502;

    &::before {
      display: none;
    }
    &-active,
    &:hover {
      opacity: 0.2;
    }
  }
}

@import "block/header.scss";
@import "block/footer.scss";
@import "block/main.scss";
@import "block/contacts.scss";
@import "block/catalog.scss";
@import "block/error.scss";
@import "block/popup.scss";
@import "block/search.scss";
@import "block/entrance.scss";

.breadcrumbs {
  margin: 0;
  padding: 45px 0 5px;
  list-style: none;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }

  & > li {
    position: relative;
    display: inline-block;

    &:not(:last-child)::after {
      display: inline-block;
      content: "/";
      margin: 0 5px;
    }
    &:hover .breadcrumbs_menu {
      visibility: visible;
      opacity: 1;
    }
    &.breadcrumbs_menu_link > a {
      position: relative;
      padding-right: 10px;
      text-decoration: underline;
    }
    &.breadcrumbs_menu_link:hover > a {
      color: $red;
    }
    &.breadcrumbs_menu_link > a::before {
      content: "";
      position: absolute;
      right: -2px;
      top: 6px;
      width: 8px;
      height: 8px;
      transform: rotate(180deg);
      background: url(/assets/images/arr.svg) center center / 100% no-repeat;
      // transition: transform .2s;
    }
    &.breadcrumbs_menu_link:hover > a::before {
      transform: rotate(0);
      filter: invert(17%) sepia(12%) saturate(4890%) hue-rotate(322deg)
        brightness(104%) contrast(105%);
    }
  }
  a {
    position: relative;
    &.active,
    &:hover {
      color: $red;
      text-decoration: underline;
    }
  }

  .breadcrumbs_menu {
    position: absolute;
    z-index: 10;
    left: -10px;
    top: 100%;
    width: 175px;
    font-size: 12px;
    padding: 8px 6px 8px 0;
    border-top: 4px solid $red;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

    .breadcrumbs_list {
      padding: 0 2px 0 14px;
      max-height: 170px;
      overflow: auto;
      scrollbar-color: #bebebe #f5f5f5;
      scrollbar-width: thin;

      @supports selector(::-webkit-scrollbar) and (scrollbar-color: auto) {
        scrollbar-color: unset;
        scrollbar-width: unset;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #f5f5f5;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #bebebe;
          border-radius: 10px;
        }
      }

      li {
        display: block;
        margin-bottom: 1px;
      }
      a {
        display: block;
        line-height: 1.1em;
        padding: 5px 0;
      }
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin: 0 0 5px;
    padding: 0 6px;

    &:hover:not(.active, .disabled) {
      cursor: pointer;
      color: #982020;
      text-decoration: underline;
    }
    &.active {
      color: #fff;
      background-color: $red;
    }
    &.prev {
      margin-left: 15px;
    }
    &.next {
      margin-right: 15px;
    }
    &.pagination__more {
      width: 100%;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: center;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  .disabled {
    opacity: 0.1;
  }
}

.compensate-for-scrollbar {
  margin-right: 0 !important;
}
.anim {
  .enter {
    opacity: 0.01;
  }
  .enter-active {
    opacity: 0.01;
  }
  .enter-done {
    opacity: 1;
    transition: opacity 150ms linear;
  }
  .exit {
    opacity: 1;
  }
  .exit-active {
    opacity: 0.01;
    transition: opacity 150ms linear;
  }
}
.swal2-container {
  z-index: 100000;
}
.swal2-popup {
  font-size: 15px;

  .swal2-title {
    font-size: 26px;
    font-family: "Montserrat-Bold", Verdana, Helvetica;
  }
  .swal2-confirm {
    background-color: #7bb638;
    color: #fff;
    font-size: 16px;
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.button_top {
  position: fixed;
  right: 3%;
  bottom: 100px;
  z-index: 100;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 60px;
  height: 60px;
  background-color: $orange;
  border-radius: 50%;
  border: none;
  box-shadow: inset 0px 0px 15px 0px rgba(255, 255, 255, 0.8);
  opacity: 0.6;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    // background-image: url(/assets/images/down_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px;
    transform: scale(1, -1);
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(322deg)
      brightness(103%) contrast(101%);
  }

  &:hover {
    opacity: 1;
    box-shadow: none;
  }
}
.gdpr {
  position: fixed;
  top: auto;
  bottom: 0;
  height: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  box-shadow: 0px 3px 38px 0px rgba(99, 99, 99, 0.38);
  padding: 15px 0;
  font-weight: 600;
  z-index: 1000;
}
.gdpr--white {
  background-color: rgba(255, 255, 255, 0.9);
  color: #090f1f;
  border-top: 3px solid #191919;
}
.gdpr .container {
  display: grid;
  grid-template-columns: 1fr 244px;
  grid-column-gap: 80px;
  align-items: center;
  box-sizing: border-box;
}
.gdpr .container:before,
.gdpr .container:after {
  display: none;
}
.gdpr p,
.gdpr small {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: 14px;
  line-height: 1.3;
}
.gdpr .container > small {
  display: none;
}
.gdpr_close {
  grid-row: 1/3;
  grid-column: 2/3;
  display: block;
  margin: 0;
  padding: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
}
.gdpr--white .gdpr_close {
  background-color: #000000;
  color: #fff;
}
.gdpr_close:hover {
  color: #000;
}
.gdpr--white .gdpr_close:hover {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .gdpr .container {
    grid-template-columns: 1fr 200px;
    grid-column-gap: 40px;
  }
}
@media screen and (max-width: 600px) {
  .gdpr {
    padding: 20px 0 15px;
  }
  .gdpr .container {
    grid-template-columns: 1fr 175px;
    grid-column-gap: 35px;
  }
  .gdpr p {
    grid-column: 1/-1;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .gdpr p small {
    display: none;
  }
  .gdpr .container > small {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }
  .gdpr_close {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 13px;
  }
}
@media screen and (max-width: 400px) {
  .gdpr .container {
    grid-template-columns: 1fr 145px;
    grid-column-gap: 20px;
  }
  .gdpr_close {
    padding: 15px 10px;
  }
}
.phones-row {
  display: flex;
  align-items: center;
  column-gap: 5px;

  &.phones-row--inline {
    display: inline-flex;
  }
  .messenger {
    flex-shrink: 0;
  }
  img {
    display: block;
  }
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Terminator", sans-serif;
  font-weight: bold;
  font-size: 28px;
  justify-self: start;
  line-height: 1em;

  .logo_sub {
    font-size: 0.4em;
  }
  .logo_text {
    line-height: 0.8;
    margin-top: 4px;
  }
  .logo img {
    display: block;
  }
  @media (max-width: 1140px) {
    font-size: 24px;
    .logo_sub {
      font-size: 0.44em;
    }
  }
  @media (max-width: 992px) and (min-width: 841px) {
    font-size: 16px;
    .logo_img {
      width: 36px;
    }
  }
  @media (max-width: 840px) and (min-width: 601px) {
    grid-row: 1/3;
    grid-column: 1/2;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    .logo_img {
      width: 36px;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
    .logo_img {
      width: 24px;
    }
  }
  @media (max-width: 360px) {
    font-size: 12px;
    .logo_img {
      width: 24px;
    }
  }
}
.scroll_top {
  display: block;
  position: fixed;
  width: 77px;
  height: 77px;
  bottom: 200px;
  left: 50%;
  transform: translateX(618px);
  border: none;
  border-radius: 10px;
  z-index: 10;
  font-size: 0px;
  padding: 0px;
  background: #f5f5f5;
  cursor: pointer;
}
.scroll_top::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("/assets/images/up_red.png") center center / 100% no-repeat;
  left: 0;
  top: 0;
}
@media (max-width: 1400px) {
  .scroll_top {
    left: auto;
    right: 25px;
    transform: none;
  }
}
@media (max-width: 1140px) {
  .scroll_top {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 768px) {
  .scroll_top {
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 40px;
  }
}
.site-creator {
  padding: 15px 0;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}
.site-creator_link {
  display: inline-block;
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 480px) {
  .site-creator {
    font-size: 14px;
  }
}
