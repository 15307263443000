footer {
	position: relative;
	z-index: 1;
	margin-top: auto;
	background-color: $red;
	background-image: linear-gradient(to bottom, $red 122px, #e0dbd9 122px);

	@media screen and (max-width: 900px) {
		background-image: linear-gradient(to bottom, $red 140px, #e0dbd9 140px);
	}
	@media screen and (max-width: 680px) {
		background: $red;
	}

	&.container {
		margin-top: auto;
	}
}
.footer {
	display: grid;
	grid-template-columns: 1fr 0.7fr auto auto auto; 
	grid-column-gap: 45px;
	grid-row-gap: 15px;
	align-items: flex-start;
	padding-top: 40px;
	padding-bottom: 10px;
  	color: #fff;
  	font-size: 15px;

	.footer__logo {
		width: 100%;
		max-width: 245px;
		position: relative;
		margin-top: -20px;		
	}

  	@media screen and (max-width: 1280px) {
		grid-template-columns: 1fr auto auto auto auto; 
	}
	@media screen and (max-width: 1199px) {
		grid-column-gap: 30px;
	}
	@media screen and (max-width: 980px) {
		grid-column-gap: 15px;
	}
	@media screen and (max-width: 900px) {
		.footer__logo {
			padding-top: 10px;
			margin-top: 0;
		}
	}
	@media screen and (max-width: 900px) and (min-width: 681px) {
		grid-template-columns: 1fr auto auto; 
		grid-row-gap: 5px;
		padding-top: 15px;

		.footer__logo {
			grid-row: 1/3;
			grid-column: 1/2;
		}
	}
	@media screen and (max-width: 680px) {
		grid-template-columns: 1fr auto auto; 
		grid-row-gap: 15px;
		padding-top: 15px;

		.footer__adr {
			grid-row: 1/2;
			grid-column: 2/4;
			align-self: end;
			margin-bottom: 15px;
		}
	}
	@media (max-width: 540px) {
		padding-top: 10px;
		padding-bottom: 15px;
		grid-row-gap: 10px;

		.footer__link,
		.footer__docs {
			display: none;
		}
		.footer__phone {
			grid-row: 2/3;
			grid-column: 1/3;
			padding-left: 42px;
			display: flex;
			align-items: center;
		}
		.footer__phone::before {
			width: 32px;
			height: 32px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}
		.footer__logo {
			padding-top: 0px;
		}
		.footer__logo img {
			display: block;
			width: 180px;
		}
		.footer__adr {
			margin-bottom: 0px;
			padding-left: 42px;
		}
		.footer__adr::before {
			width: 34px;
			height: 34px;
			margin: 0;
			transform: translateY(-50%);
		}
	}
	@media (max-width: 480px) {
		.footer__adr {
			font-size: 12.5px;
		}
	}
	
	p {
		padding-top: 0;
		padding-bottom: 0;
	}
	.footer__phone {
		a {
			display: block;
		}
		@media (max-width: 540px) {
			& > a {
				display: none;
			}
			& .phones-row {
				min-height: 34px;				
			}
			& .phones-row img {
				width: 24px;
				height: 24px;
			}
		}
		
	}
	.footer__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 45px;
		height: 45px;
		background-color: #fff;
		border-radius: 5px;

		@media (max-width: 540px) {
			width: 32px;
			height: 32px;
		}
	}
	@media (min-width: 1025px) {
		.footer__docs {
			padding-left: 35px;
		}
	}
	.footer__btn {
		display: inline-block;
		padding: 13px 15px;
		color: $red;
		font-size: 14px;
		font-weight: 600;
		background-color: #fff;
		border-radius: 5px;
		border: none;
		cursor: pointer;

		&:hover {
			background-color: $gray;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		}
	}
	.footer__politic {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		text-align: center;
		color: #000;
		font-size: 12px;

		@media screen and (max-width: 680px) {
			margin-top: 5px;
			margin-bottom: 0;
			color: #fff;
		}
	}
	.footer__copy {
		align-self: center;
		padding: 0;
		font-size: 11px;
		color: #5e5e5e;
		
		& a {
			display: inline-block;
			text-decoration: underline;
			color: $red;
		}
		& p + p {
			margin-top: 5px;
		}
		@media (max-width: 680px) {
			grid-column: 1/-1;
			color: #ffffff;
			text-align: center;
			padding-top: 10px;
			& a {
				color: inherit;
			}
			& p + p {
				margin-top: 0px;
			}
		}
	}
	.footer__menu {
		grid-column: 2/-1;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 15px;
		color: #5e5e5e;

		@media screen and (max-width: 1199px) {
			font-size: 13px;
		}
		@media screen and (max-width: 900px) {
			display: none;
		}
		a {
			display: block;
			padding: 5px 0;
			&:hover,
			&.active {
				color: #691b1b;
				font-weight: 600;
			}
		}
	}
}